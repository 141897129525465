.card {
  display: flex;
  justify-content: space-between;
  margin: 60px 0 0 0;
  flex-flow: row wrap;

  @media #{$mobile} {
    align-items: stretch;
  }

  .home-card {
    display: flex;
    justify-content: space-around;
    background-color: $white;
    border-radius: 3%;
    width: 16%;
    flex-basis: 16%;
    margin: 0;
    padding: 10px;
    margin-right: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0.5;
    &:hover,
    &.selected {
      box-shadow: 0 -2px 5px 2px #d8d8d8;
      margin-top: -5px;
      opacity: 1;
    }

    @media #{$mobile} {
      flex-basis: 44%;
      width: 50%;
      margin-bottom: 20px;
      margin-left: 10px;
      align-items: center;
    }

    .card-info {
      text-align: center;
      font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;

      @media #{$mobile} {
        margin-left: 15px;
      }

      .number-info {
        font-size: 2.5rem;
        margin: 0;

        @media #{$mobile} {
          font-size: 1.5rem;
        }
      }

      .text-info {
        margin: 0;
      }
    }

    .card-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 86px;
      height: 86px;
      border-radius: 5%;

      @media #{$mobile} {
        padding-right: 25px;
        padding-left: 25px;
        width: 60px;
        height: 60px;
      }

      .icon {
        @media #{$mobile} {
          width: 32px;
          height: 32px;
        }
      }
    }

    .title-color-one {
      background-color: $dark-sky-blue;
    }

    .title-color-two {
      background-color: $dark-blue;
    }

    .title-color-three {
      background-color: $dark-light-green;
    }

    .title-color-four {
      background-color: $dark-light-grey;
    }

    .title-color-five {
      background-color: $plexo-red;
    }

    .title-color-new {
      background-color: $dark-light-green;
    }
  }
}

.addButton {
  position: fixed !important;
  right: 40px !important;
  bottom: 40px !important;
  z-index: 20 !important;
}
