.profile {
  padding-top: 30px;

  @media #{$mobile} {
    padding-top: 0;
  }

  .section-title {
    text-align: left;
    margin-top: 24px;
    margin-bottom: 0;
    padding-left: 15px;
  }

  .setting-link {
    display: flex;
    align-items: center;
    padding-right: 12px;
    img {
      border: 8px solid #7fc15e;
      background-color: #7fc15e;
      margin-right: 4px;
      border-radius: 4px;
      width: 40px;
    }
  }
}
