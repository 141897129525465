.map-stop-info {
  display: inline-block;
  background-color: rgba(4, 4, 4, 0.7);
}
.map-rebound-btn {
  display: inline-block;
  position: absolute;
  background-color: white;
  box-sizing: border-box;
  height: fit-content;
  top: 10px;
  right: 10px;
}
.map-courier-info-box {
  background-color: rgba(4, 4, 4, 0.7);
  color: white;
  padding: 4px;
  width: -moz-fit-content;
  width: fit-content;

  img {
    display: none;
  }
  .map-courier-info-name {
    display: flex;
    align-items: flex-end;
    white-space: nowrap;
    svg {
      height: 16px;
    }
  }
  .map-courier-info-time {
    display: flex;
    align-items: flex-end;
    white-space: nowrap;
    color: silver;
    svg {
      height: 14px;
    }
  }
}
