$bg: #f6f6f6;

.chatbox-main-container {
  display: flex;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 10px;

  .scrollbox {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;

    &.align-bottom {
      flex-direction: column-reverse;
    }
  }

  .cahtbox-sidebar {
    // border-right: 1px solid #ddd;
    // box-shadow: 5px 0 8px -7px #ccc;
    display: flex;
    flex-direction: column;

    .chatbox-sidear-header {
      border-bottom: 1px solid $bg;
      box-shadow: 0 10px 10px -7px $bg;
    }

    .chatbox-sidebar-body {
      flex-grow: 1;
      position: relative;
    }

    .chatbox-sidebar-footer {
      border-top: 1px solid $bg;
      box-shadow: 0 -5px 8px -7px $bg;
    }
  }

  .chatbox-chat-wrapper {
    .chatbox-searchbox {
      position: absolute;
      z-index: 100;
    }

    .chatbox-conversation-box {
      position: relative;
    }

    .chatbox-chat-input {
      border-radius: 3px;
      box-shadow: 2px 2px 5px 0 #aaa;


    }
  }
}