$title-color: #7fc15e;
$color-send-to-today-back: #85dafb;
$color-send-to-today-highlight: #22c989;
$color-send-to-today: #1e637e;
$color-link: #1e637e;
$borderWidth: 8px;

$color0: #aacbb5;
$color1: #dec59d;
$color2: #7bacec;
$color3: #e3a7dd;
$color4: #4a9c8e;
$color5: #ff70a6;
$color6: #fbbc85;
$color7: #83f8ff;
$color8: #f70945;
$color9: #887dd3;

$colorCOMPLETED: #cedfce;
$colorATTENTION: #ff8902;

.trips-dnd-full-page {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
}

.trips-dnd {
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease;
  margin: 0;
  border: 1px solid $title-color;

  &.no-border {
    border: none;
  }

  flex-grow: 1;

  * {
    box-sizing: border-box;
  }

  .trips-dnd-title-area {
    display: flex;
    padding: 8px;
    background: $title-color;
    color: white;
    align-items: center;

    * {
      color: white;
      border-color: white;
    }

    .title {
      display: flex;
      font-weight: bold;
      font-size: large;
    }

    .search-area {
      display: flex;
      flex-grow: 1;
      justify-content: center;
      font-size: large;

      .MuiOutlinedInput-root {
        width: 500px;
        background-color: lighten($title-color, 5);

        fieldset {
          border-color: lighten($title-color, 40);
        }

        .MuiOutlinedInput-input {
          color: lighten($title-color, 40);
        }
      }

      .search-icon {
        color: lighten($title-color, 40);
      }
    }

    .actions {
      display: flex;
    }
  }

  .chart-area {
    overflow: hidden;
  }

  .courier-area {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 8px;

    .courier-selector {
      display: inline-flex;
      width: calc(100% - 32px);
    }

    .lock-area {
      display: inline-flex;
      width: 26px;
      justify-content: end;

      .lock {
        opacity: 0.2;
        cursor: pointer;

        &.locked {
          opacity: 1;
        }
      }
    }

    .courier-selector-link {
      padding: 4px;
      display: block;
      font-weight: bold;
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      cursor: pointer;

      &:hover {
        color: blue;
        text-decoration: underline;
      }

      &.empty {
        font-style: italic;
        color: gray;
      }
    }

    .courier-label {
      padding: 4px;
      display: block;
      text-overflow: ellipsis;
      overflow: hidden;

      &.empty {
        font-style: italic;
        font-weight: normal;
        color: gray;
      }
    }

    .trip-info {
      display: block;
      width: calc(100% - 32px);

      .trip-status {
        display: block;
        font-size: 70%;
        line-height: 8px;
        padding-left: 4px;
      }

      .courier-label {
        display: inline-block;
        line-height: 12px;
        width: 100%;

        &.PENDING {
          font-style: normal;
        }

        &.NEW {
          font-style: italic;
          text-decoration: underline;
        }

        &.IN_TRANSIT {
          font-style: normal;
          font-weight: bold;
        }

        &.CANCELLED {
          font-style: normal;
          color: silver;
          text-decoration: line-through;
        }

        &.FINISHED {
          font-style: normal;
          font-weight: bold;
          color: gray;
        }
      }
    }

    .trip-menu {
      display: inline-flex;
      width: 26px;
      justify-content: center;
    }

    .visible-on-map {
      display: inline-flex;
      width: 26px;
      justify-content: center;
    }

    .none-menu-item {
      color: gray !important;
    }
  }

  .map-area {
    display: flex;
    flex-direction: column;
    position: relative;
    flex-grow: 1;
    position: relative;

    .map-area-google-map {
      display: flex;
      flex-grow: 1;
    }
    .spinner {
      display: none;
      position: absolute;
      align-items: center;
      justify-content: center;
      top: 50%;
      left: 50%;
      width: 128px;
      height: 128px;
      margin-top: -64px;
      margin-left: -64px;
      z-index: 9999999999;
    }
    &.loading {
      > * {
        opacity: 0.3;
      }
      .spinner {
        display: flex;
        opacity: 1;
      }
    }
  }
}

.trip-popup-menu {
  .menu-title {
    font-size: small;
    color: #aaa;
    display: block;
    padding-left: 8px;
    text-align: left;
  }
}

.stop-tooltip {
  padding: 4px;

  .data-row {
    display: flex;
    align-items: center;
    color: white;
  }

  .icon {
    color: #aaa;
    font-size: large;
    margin-right: 4px;
  }

  .info {
    margin-top: 8px;
    color: yellow;

    .icon {
      color: yellow;
    }
  }

  .stop-arrive-time {
    display: inline-block;
    padding-right: 8px;
  }

  .stop-arrive-time-change {
    display: inline-block;
    cursor: pointer;
    color: lightblue;
  }

  .stop-time-window {
    display: flex;
    white-space: nowrap;
    color: yellow;

    div {
      display: flex;

      &:last-child::before {
        display: inline-block;
        padding: 0 4px 0 4px;
        content: '\2192';
      }
    }
  }
}

.metrics-area {
  display: inline-block;
  position: relative;
  margin-left: 8px;
  z-index: 19;

  > div {
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: $title-color 0 0 5px 0px;
    padding: 2px 4px;
    margin-bottom: 2px;
    font-size: x-small;
    line-height: 10px;
    height: 14px;
    color: darken($title-color, 10);
    align-items: center;
    &.inline{
      display: inline-flex;
      margin-right: 4px;
    }
  }
  .zone-caption{
    background-color:darken($title-color,20);
    color: white;
  }


  .icon {
    display: inline-block;
    height: 16px;
    margin-left: 2px;
    margin-right: -4px;
  }

  .total-price {
    color: Yellow;
    background-color: red;
    font-weight: bold;
    font-size: 110%;

    .icon {
      margin: 0 0 0 -8px;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      padding: 0;
      background-color: red;
      color: white;
    }
  }

  .round-trip-action {
    margin-left: 4px;
    color: $color-link;
    cursor: pointer;
  }
}

.dndt-row-item {
  overflow: visible !important;
  // border: none !important;
  border-radius: 5px;
  position: relative;

  // box-shadow: 0 0 1px $borderWidth #444;
  &:has(.computed-missed) {
    opacity: 0.5;
  }

  &:has(.CURRENT)::after {
  }

  &:has(.COMPLETED) {
    background-color: $colorCOMPLETED;
    color: gray;
  }

  &:has(.ATTENTION)::before {
    position: absolute;
    display: block;
    content: '\25EC';
    color: $colorATTENTION;
    width: 20px;
    line-height: 8px;
    top: -12px;
    left: 50%;
    margin-left: -10px;
    font-size: 16px;
    text-align: center;
  }

  .stop {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: bold;
  }
}

.dndt-row-spacer {
  height: $borderWidth !important;
  border-top: 1.75px solid #444;
  border-bottom: 1.75px solid #444;
}

.color-0 {
  .dndt-row-item {
    box-shadow: 0 0 1px 1.5px $color0;
  }
}

.color-1 {
  .dndt-row-item {
    box-shadow: 0 0 1px 1.5px $color1;
  }

  .dndt-row-spacer:not(.first) {
    background-color: $color1 !important;
  }
}

.color-2 {
  .dndt-row-item {
    box-shadow: 0 0 1px 1.5px $color2;
  }

  .dndt-row-spacer:not(.first) {
    background-color: $color2 !important;
  }
}

.color-3 {
  .dndt-row-item {
    box-shadow: 0 0 1px 1.5px $color3;
  }

  .dndt-row-spacer:not(.first) {
    background-color: $color3 !important;
  }
}

.color-4 {
  .dndt-row-item {
    box-shadow: 0 0 1px 1.5px $color4;
  }

  .dndt-row-spacer:not(.first) {
    background-color: $color4 !important;
  }
}

.color-5 {
  .dndt-row-item {
    box-shadow: 0 0 1px 1.5px $color5;
  }

  .dndt-row-spacer:not(.first) {
    background-color: $color5 !important;
  }
}

.color-6 {
  .dndt-row-item {
    box-shadow: 0 0 1px 1.5px $color6;
  }

  .dndt-row-spacer:not(.first) {
    background-color: $color6 !important;
  }
}

.color-7 {
  .dndt-row-item {
    box-shadow: 0 0 1px 1.5px $color7;
  }

  .dndt-row-spacer:not(.first) {
    background-color: $color7 !important;
  }
}

.color-8 {
  .dndt-row-item {
    box-shadow: 0 0 1px 1.5px $color8;
  }

  .dndt-row-spacer:not(.first) {
    background-color: $color8 !important;
  }
}

.color-9 {
  .dndt-row-item {
    box-shadow: 0 0 1px 1.5px $color9;
  }

  .dndt-row-spacer:not(.first) {
    background-color: $color9 !important;
  }
}

.courier-with-avatar {
  display: flex;
  align-items: center;

  .courier-avatar {
    width: 24px;
    height: 24px;
    background: transparent center center no-repeat;
    background-size: contain;
    margin-right: 8px;
  }
}

.dndt-recycle-row-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .dndt-recycle-send-to-today {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-send-to-today-back;
    color: $color-send-to-today;
    top: 0;
    left: -10%;
    width: 0;
    height: 100%;
    overflow: hidden;

    &.expand {
      width: 60%;
      left: 0;
    }

    &.highlight {
      // background-color: $color-send-to-today-highlight;
      left: 0;
      width: 100%;
    }
  }
}
