.item-pending-fulfillment-accordion {
  .ipf-head {
    display: flex;
    width: 100%;
    padding: 16px 16px 16px 0px;
    &.title {
      padding-bottom: 0;
      padding-top: 0;
    }
    .sortable-field {
      display: inline-flex;
      align-content: center;
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .ipf-label {
      width: 10%;
      span {
        display: block;
      }
    }
    .ipf-name {
      width: 16%;
    }
    .ipf-description {
      width: 22%;
    }
    .ipf-committed {
      width: 12%;
      text-align: center;
    }
    .ipf-ordered {
      width: 12%;
      text-align: center;
    }
    .ipf-fulfilled {
      width: 12%;
      text-align: center;
    }
    .ipf-status {
      width: 16%;
    }
  }
  
  .ipf-action-area {
    justify-content: end;
  }
  
}
.menu-title {
  font-size: small;
  color: #aaa;
  display: block;
  padding-left: 8px;
  text-align: left;
}
