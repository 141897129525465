.third-party-info {
  table {
    border: 1px dotted gray;
    border-radius: 5px;

    table {
      border: none;
    }

    padding: 5px;
    border-spacing: 5px;
    border-collapse: separate;

    tr {
      background-color: aliceblue;

      td {
        padding: 5px;
        color: gray;

        &:last-child {
          color: rgb(51, 120, 180);
        }
      }

      &:nth-child(odd) {
        td {
          background-color: darken(aliceblue, 3);
        }
      }
    }
  }
}