.delivery-order-accordion {
  .doa-head {
    display: flex;
    width: 100%;
    padding: 16px 16px 16px 0px;
    &.title {
      padding-bottom: 0;
      padding-top: 0;
    }
    .sortable-field {
      display: inline-flex;
      align-content: center;
      a {
        text-decoration: none;
        display: flex;
        align-items: center;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .doa-label {
      width: 10%;
      span {
        display: block;
      }
    }
    .doa-name {
      width: 12%;
    }
    .doa-company {
      width: 10%;
    }
    .doa-address {
      width: 15%;
      &.expand {
        width: 75%;
      }
    }
    .doa-addressee{
      width:10%;
    }
    .doa-packages-count {
      width: 5%;
      text-align: center;
    }
    .doa-time {
      width: 13%;
      text-align: center;
      justify-content: center;
    }
    .doa-shipVia {
      width: 10%;
    }
    .doa-status {
      width: 15%;
    }
  }
  &.NEEDS_ATTENTION {
    .doa-status {
      color: red;
    }
  }
  span.package_status,
  span.package_comment {
    &.FAILED,
    &.FAILED_ON_LOAD,
    &.FAILED_ON_DROP_OFF {
      color: red;
    }
  }
  .doa-action-area {
    justify-content: end;
  }
  .doa-time-window-alert {
    display: flex;
    align-items: center;
    .time-window {
      display: inline;
    }
  }
}
.menu-title {
  font-size: small;
  color: #aaa;
  display: block;
  padding-left: 8px;
  text-align: left;
}
