.prospero-chat-session-header-item {
  margin: 8px 0 0 0 !important;
  cursor: pointer;
  opacity: 0.7;
  border-bottom: 1px dotted #ccc;

  &:hover {
    opacity: 1;
  }

  &:last-child {
    border-bottom: none;
  }

  &.current,
  &:hover.current {
    cursor: default;
    background-color: #eeeeee;
    opacity: 1;
  }

  .prospero-chat-session-header-item-date {
    color: #444;
    text-align: right;
  }
}

.AIdbId {
  display: inline-block;
  code {
    font-size: 90%;
    color: white;
    border: 1px solid;
    margin: 0 8px 0 8px;
    border-radius: 5px;
    padding: 2px 4px 2px 4px;
    background-color: rgb(138, 138, 0);
  }
  span {
    font-size: 90%;
    color: rgb(138, 138, 0);
  }
}
